import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IThingsList } from '../../model';

const ThingsList: FC<IThingsList> = ({ thingsList }) => {
  return (
    <ul className="read-report__things-list">
      {thingsList.map(({ properties: { text } }) => (
        <DangerouslySetInnerHtml
          key={text}
          className="read-report__things-list-item"
          element="li"
          html={text}
        />
      ))}
    </ul>
  );
};

export default ThingsList;
