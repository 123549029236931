import React, { FC } from 'react';
import classnames from 'classnames';

import { IWords } from '../../model';

const WordsList: FC<IWords> = ({ words }) => (
  <ul className="read-report__words-list">
    {words.map(({ properties: { word, background } }) => {
      const { label } = background?.[0]?.properties?.colorPicker || {};

      return (
        <li
          key={word}
          className={classnames('read-report__words-list-item', {
            [`${label}-default-bg`]: label,
          })}
        >
          {word}
        </li>
      );
    })}
  </ul>
);

export default WordsList;
