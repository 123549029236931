import { graphql } from "gatsby"
import React, { FC } from "react"
import classnames from "classnames"
import { Container } from "react-bootstrap"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import NFButton from "components/common/NFButton"
import UniversalImage from "components/common/UniversalImage"
import DangerouslySetInnerHtml from "components/common/DangerouslySetInnerHtml"

import WordsList from "./components/WordsList"
import ThingsList from "./components/ThingsList"
import { IReadReports } from "./model"

import "./ReadReportSection.scss"

const ReadReportSection: FC<IReadReports> = ({
  title,
  rteTitle,
  description,
  sectionAnchor,
  image,
  imageAlt,
  imageDescription,
  imageBackground,
  anchorText,
  link,
  fileLink,
  icon,
  sectionBackground,
  sectionTheme,
  note,
  words,
  thingsList,
}) => {
  const { name, url, target } = link?.[0] || {}
  const { relativePath } = fileLink?.gatsbyDocFile || {}
  const { label } = sectionBackground?.[0]?.properties?.colorPicker || {}
  const imageBackgroundLabel =
    imageBackground?.[0]?.properties?.colorPicker?.label
  const downloadIcon = icon?.svg?.content
  const isDarkTheme = ["navy", "red", "red-ribbon", "amber-darken"].includes(
    label || ""
  )

  return (
    <section
      className={classnames("read-report", {
        [`${label}-default-bg`]: label,
        "read-report--dark": isDarkTheme,
        [`${sectionTheme}`]: sectionTheme,
      })}
    >
      <Container fluid id={sectionAnchor}>
        <Row className="read-report__row">
          <Col className="read-report__text">
            {rteTitle ? (
              <DangerouslySetInnerHtml
                className="read-report__title"
                html={rteTitle}
              />
            ) : (
              <h2 className="read-report__title">{title}</h2>
            )}
            <DangerouslySetInnerHtml
              className="read-report__description"
              html={description}
            />
            {relativePath && anchorText ? (
              <a
                href={relativePath}
                className="read-report__download-link read-report__download-link--hide-xs nf-btn"
                download
              >
                {anchorText}
                {downloadIcon ? (
                  <DangerouslySetInnerHtml
                    className="read-report__download-icon"
                    html={downloadIcon}
                  />
                ) : null}
              </a>
            ) : null}
            {name && url ? (
              <NFButton
                btnText={name}
                link={url}
                target={target}
                className="read-report__link"
              />
            ) : null}
            <DangerouslySetInnerHtml
              className="read-report__note"
              html={note}
            />
          </Col>

          {image ? (
            <Col
              className={classnames("read-report__image", {
                [`${imageBackgroundLabel}-bg`]: label,
                "has-description": imageDescription?.length,
              })}
            >
              <UniversalImage img={image} imageAlt={imageAlt} />
              {imageDescription ? (
                <div className="read-report__image-description">
                  <span className="read-report__image-description-text">
                    {imageDescription}
                  </span>
                </div>
              ) : null}
            </Col>
          ) : null}

          {words?.length ? (
            <Col className="read-report__words">
              <WordsList words={words} />
            </Col>
          ) : null}

          {thingsList?.length ? <ThingsList thingsList={thingsList} /> : null}

          {relativePath && anchorText ? (
            <a
              href={relativePath}
              className="read-report__download-link read-report__download-link--hide-md nf-btn"
              download
            >
              {anchorText}
              {downloadIcon ? (
                <DangerouslySetInnerHtml
                  className="read-report__download-icon"
                  html={downloadIcon}
                />
              ) : null}
            </a>
          ) : null}
        </Row>
      </Container>
    </section>
  )
}

export default ReadReportSection
